import React from 'react';

export default function (props) {
  return (
    <svg viewBox="0 0 70 37" {...props}>
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="2322.655"
          cy="19346.564"
          fx="2322.655"
          fy="19346.564"
          r="145.594"
          gradientTransform="translate(-511.123 265.427) scale(.235 -.012)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".005" stop-color="#5f6468" />
          <stop offset=".145" stop-color="#6a6e72" />
          <stop offset=".388" stop-color="#868a8d" />
          <stop offset=".703" stop-color="#b4b6b8" />
          <stop offset="1" stop-color="#e6e6e6" />
        </radialGradient>
      </defs>
      <ellipse
        cx="35.7"
        cy="34.2"
        rx="34.3"
        ry="1.8"
        style={{ fill: 'url(#radial-gradient)', opacity: 0.7, strokeWidth: 0 }}
      />
      <g>
        <path
          d="M69.4,3s.1,21.4-.7,24l-4.24.093s-1.127-2.876-4.327-2.876c-2.5,0-4.347,2.876-4.347,2.876l-39.185-.093s-1.5-2.9-4.7-2.9c-2.1,0-3.854,2.313-3.854,2.313l-7.646-.113,1-5.6s.1-1.5,1.9-1.9c1.8-.4,5.6-1.5,5.6-1.5l8.9-10.9h-1.9s-.7-.2-.7-.7.2-2.1,1.2-3.1c.9-.9,2-1.7,3.1-1.7h47.7s2.2-.1,2.2,2.1Z"
          style={{ fill: '#24c0da', strokeWidth: 0 }}
        />
        <path
          d="M24.8,6h6.9c.6,0,1.2-.5,1.2-1.2v-2c0-.6-.5-1.2-1.2-1.2h-11.3s-2.3-.4-4,2.1c0,0-.6.8-.6,1.7,0,0-.1.5.6.5s8.4.1,8.4.1Z"
          style={{ fill: '#24c0da', strokeWidth: 0 }}
        />
        <g style={{ opacity: 0.5 }}>
          <path
            d="M48,3c-3.6,0-6.5,2.7-7,6.4,1.5.2,2.8.8,3.9,1.8v-1.3c0-.8.4-1.5.9-2.1.6-.5,1.3-.8,2.1-.8,1.8,0,3.1,1.4,3.1,3.3v10.8h-3.9v-3.6c0-4-3.3-7.2-7.4-7.2s-7.4,3.3-7.4,7.4,3.2,7.4,7.2,7.4h2.6v-3.9h-2.5c-1.8,0-3.3-1.5-3.3-3.4s1.5-3.4,3.3-3.4c1.9,0,3.4,1.5,3.4,3.3v7.3h11.9v-14.7c.2-4.1-2.9-7.3-6.9-7.3"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <path
          d="M68.7,26.9h.5c.5,0,.8.4.8.8v.6c0,.5-.4.8-.8.8H17.1l-.5-2.1h52.1v-.1Z"
          style={{ fill: '#050505', strokeWidth: 0 }}
        />
        <path d="M68.6,29s-.9,1.9-3.7,1.9H15.8v-1.9h52.8Z" style={{ fill: '#71ccdd', strokeWidth: 0 }} />
        <circle cx="60.3" cy="29" r="4.9" style={{ fill: '#050505', strokeWidth: 0 }} />
        <circle cx="60.3" cy="29" r="4" style={{ fill: '#1b1c1c', strokeWidth: 0 }} />
        <circle cx="60.3" cy="29" r="2.3" style={{ fill: '#dcd9dc', strokeWidth: 0 }} />
        <path d="M21.6,2.3s-1.2,1.2-1.2,2.8h-3.9c.1-.1.5-3.3,5.1-2.8Z" style={{ fill: '#24c0da', strokeWidth: 0 }} />
        <path d="M8.2,26.3H.4l-.4,1.8s.1,3.2,1.8,3.2h6.4v-5Z" style={{ fill: '#2b242d', strokeWidth: 0 }} />
        <circle cx="60.3" cy="29" r="1.7" style={{ fill: '#5f5f61', strokeWidth: 0 }} />
        <circle cx="12.1" cy="29" r="4.9" style={{ fill: '#050505', strokeWidth: 0 }} />
        <circle cx="12.1" cy="29" r="4" style={{ fill: '#1b1c1c', strokeWidth: 0 }} />
        <circle cx="12.1" cy="29" r="2.3" style={{ fill: '#dcd9dc', strokeWidth: 0 }} />
        <circle cx="12.1" cy="29" r="1.7" style={{ fill: '#5f5f61', strokeWidth: 0 }} />
        <path
          d="M21.4,19.6h1.2c.2,0,.4-.1.4-.4h0c0-.2-.1-.4-.4-.4h-1.2c-.2,0-.4.1-.4.4h0c-.1.3.1.4.4.4Z"
          style={{ fill: '#ebebed', strokeWidth: 0 }}
        />
        <path d="M24,7.2v7.5l-13.3,1.9,6.6-8.6s.6-.8,2.1-.8h4.6Z" style={{ fill: '#ebebed', strokeWidth: 0 }} />
        <path
          d="M4.243,18.748l-1.1,2.1-1.5.5-.1-.1s0-1.7,1.2-2.1c1-.4,1.4-.5,1.5-.4Z"
          style={{ fill: '#ededef', strokeWidth: 0 }}
        />
        <rect x="5.8" y="23.1" width="2.1" height="1.7" style={{ fill: '#ededef', strokeWidth: 0 }} />
      </g>
      <g>
        <g>
          <path
            d="M29.35,12.942l-.012-8.076c0-.057.022-.106.066-.15s.098-.065.162-.065h.3l4.788,4.607h.012v-4.26c0-.064.022-.118.066-.162s.098-.066.162-.066h1.44c.064,0,.118.024.162.072s.066.101.066.156v8.076c0,.056-.022.105-.066.149s-.098.066-.162.066h-.191c-.064,0-.116-.021-.156-.061l-4.74-4.764h-.012v4.477c0,.063-.022.117-.066.161-.044.045-.098.066-.162.066h-1.428c-.056,0-.108-.024-.156-.072s-.072-.1-.072-.155Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M29.866,4.65l4.788,4.607h.012v-4.26c0-.064.022-.118.066-.162.044-.044.098-.066.162-.066h1.44c.064,0,.118.024.162.072.044.048.066.101.066.156v8.076c0,.056-.022.105-.066.149-.044.044-.098.066-.162.066h-.191c-.064,0-.116-.021-.156-.061l-4.74-4.764h-.012v4.477c0,.063-.022.117-.066.161-.044.045-.098.066-.162.066h-1.428c-.056,0-.108-.024-.156-.072s-.072-.1-.072-.155l-.012-8.076c0-.057.022-.106.066-.15.044-.044.098-.065.162-.065h.3M30.067,4.15h-.501c-.196,0-.379.075-.516.212s-.212.316-.212.504l.012,8.077c0,.132.038.328.218.508.141.141.322.219.509.219h1.428c.198,0,.383-.077.52-.217.133-.133.208-.316.208-.511v-3.278l3.898,3.917c.134.134.315.208.511.208h.191c.195,0,.378-.076.516-.213.137-.137.213-.316.213-.503V4.998c0-.182-.069-.353-.198-.494-.139-.151-.327-.234-.53-.234h-1.44c-.195,0-.378.076-.516.213-.137.137-.212.32-.212.516v3.096l-3.954-3.804-.145-.14h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M38.626,12.942v-7.944c0-.056.024-.108.072-.156s.1-.072.155-.072h1.44c.056,0,.108.024.156.072s.072.101.072.156v7.944c0,.056-.024.107-.072.155s-.101.072-.156.072h-1.44c-.056,0-.107-.024-.155-.072s-.072-.1-.072-.155Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M40.294,4.77c.056,0,.108.024.156.072.048.048.072.101.072.156v7.944c0,.056-.024.107-.072.155-.048.048-.101.072-.156.072h-1.44c-.056,0-.107-.024-.155-.072-.048-.048-.072-.1-.072-.155v-7.944c0-.056.024-.108.072-.156.048-.048.1-.072.155-.072h1.44M40.294,4.27h-1.44c-.132,0-.328.038-.509.219-.141.141-.219.322-.219.51v7.944c0,.132.038.328.219.509.181.181.376.219.509.219h1.44c.188,0,.369-.078.51-.219.181-.181.219-.376.219-.509v-7.944c0-.188-.078-.369-.219-.51-.141-.141-.322-.219-.51-.219h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M43.24,12.036c-.845-.836-1.267-1.854-1.267-3.055s.422-2.222,1.267-3.065c.844-.844,1.865-1.266,3.065-1.266,1.12,0,2.088.371,2.904,1.115.12.112.124.225.012.336l-.936.973c-.104.096-.204.096-.3,0-.456-.408-.996-.612-1.62-.612-.672,0-1.232.242-1.681.726-.447.484-.672,1.066-.672,1.746,0,.672.227,1.246.679,1.723.451.476,1.014.714,1.686.714.624,0,1.16-.192,1.608-.576.111-.096.216-.092.312.012l.937.996c.104.104.1.212-.013.324-.8.776-1.771,1.164-2.916,1.164-1.2,0-2.222-.418-3.065-1.254Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M46.306,4.65c1.12,0,2.088.371,2.904,1.115.12.112.124.225.012.336l-.936.973c-.052.048-.103.072-.153.072s-.099-.024-.147-.072c-.456-.408-.996-.612-1.62-.612-.672,0-1.232.242-1.681.726-.447.484-.672,1.066-.672,1.746,0,.672.227,1.246.679,1.723.451.476,1.014.714,1.686.714.624,0,1.16-.192,1.608-.576.053-.046.105-.069.155-.069.054,0,.106.027.156.081l.937.996c.104.105.1.212-.013.324-.8.776-1.771,1.164-2.916,1.164-1.2,0-2.222-.418-3.065-1.254-.845-.836-1.267-1.854-1.267-3.055s.422-2.222,1.267-3.065c.844-.844,1.865-1.266,3.065-1.266M46.306,4.15c-1.332,0-2.482.475-3.419,1.412-.938.936-1.413,2.087-1.413,3.419s.476,2.481,1.415,3.41c.937.928,2.086,1.399,3.417,1.399,1.274,0,2.372-.439,3.264-1.305.311-.311.317-.735.02-1.035l-.927-.987c-.141-.153-.327-.238-.52-.238-.119,0-.299.033-.481.19-.358.306-.777.455-1.282.455-.534,0-.967-.183-1.323-.558-.364-.384-.541-.835-.541-1.379,0-.554.176-1.014.539-1.407.352-.38.782-.565,1.313-.565.501,0,.922.159,1.287.485.16.161.351.199.48.199.123,0,.308-.035.491-.203l.958-.995c.185-.183.223-.384.221-.518-.002-.136-.047-.339-.252-.53-.911-.831-2.002-1.25-3.246-1.25h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M50.542,9.954v-4.956c0-.056.023-.108.072-.156.048-.048.1-.072.155-.072h1.477c.063,0,.118.022.162.066s.065.098.065.162v4.86c0,.424.14.781.42,1.073s.632.438,1.057.438c.432,0,.79-.146,1.073-.438.284-.292.427-.649.427-1.073v-4.86c0-.064.021-.118.065-.162s.098-.066.162-.066h1.477c.056,0,.107.024.155.072s.072.101.072.156v4.956c0,.912-.336,1.696-1.008,2.352-.672.656-1.48.984-2.424.984-.937,0-1.738-.328-2.406-.984-.668-.655-1.002-1.439-1.002-2.352Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M57.154,4.77c.056,0,.107.024.155.072.048.048.072.101.072.156v4.956c0,.912-.336,1.696-1.008,2.352-.672.656-1.48.984-2.424.984-.937,0-1.738-.328-2.406-.984-.668-.655-1.002-1.439-1.002-2.352v-4.956c0-.056.023-.108.072-.156.048-.048.1-.072.155-.072h1.477c.063,0,.118.022.162.066.044.044.065.098.065.162v4.86c0,.424.14.781.42,1.073.28.292.632.438,1.057.438.432,0,.79-.146,1.073-.438.284-.292.427-.649.427-1.073v-4.86c0-.064.021-.118.065-.162.044-.044.098-.066.162-.066h1.477M57.154,4.27h-1.477c-.195,0-.378.076-.516.213-.137.137-.212.32-.212.516v4.86c0,.294-.091.525-.285.725-.19.196-.417.287-.715.287-.288,0-.509-.09-.696-.285-.191-.199-.281-.43-.281-.727v-4.86c0-.196-.075-.379-.212-.516-.137-.137-.32-.213-.516-.213h-1.477c-.132,0-.328.038-.509.219-.14.137-.219.319-.219.51v4.956c0,1.047.388,1.959,1.152,2.708.761.748,1.689,1.127,2.756,1.127,1.075,0,2.008-.379,2.773-1.127.769-.75,1.158-1.661,1.158-2.709v-4.956c0-.188-.078-.369-.219-.51-.181-.181-.376-.219-.509-.219h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M33.868,22.036c-.844-.836-1.266-1.854-1.266-3.055s.422-2.222,1.266-3.065c.844-.844,1.866-1.266,3.066-1.266,1.12,0,2.088.371,2.903,1.115.12.112.124.225.013.336l-.937.973c-.104.096-.204.096-.3,0-.456-.408-.996-.612-1.62-.612-.672,0-1.231.242-1.68.726-.448.484-.672,1.066-.672,1.746,0,.672.226,1.246.678,1.723.452.476,1.014.714,1.687.714.624,0,1.159-.192,1.607-.576.112-.096.216-.092.312.012l.936.996c.104.104.1.212-.012.324-.8.776-1.772,1.164-2.916,1.164-1.2,0-2.223-.418-3.066-1.254Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M36.935,14.65c1.12,0,2.088.371,2.903,1.115.12.112.124.225.013.336l-.937.973c-.052.048-.103.072-.153.072s-.099-.024-.147-.072c-.456-.408-.996-.612-1.62-.612-.672,0-1.231.242-1.68.726-.448.484-.672,1.066-.672,1.746,0,.672.226,1.246.678,1.723.452.476,1.014.714,1.687.714.624,0,1.159-.192,1.607-.576.054-.046.106-.069.156-.069.054,0,.106.027.157.081l.936.996c.104.104.1.212-.012.324-.8.776-1.772,1.164-2.916,1.164-1.2,0-2.223-.418-3.066-1.254-.844-.836-1.266-1.854-1.266-3.055s.422-2.222,1.266-3.065c.844-.844,1.866-1.266,3.066-1.266M36.935,14.15c-1.332,0-2.483.475-3.42,1.412-.937.937-1.413,2.087-1.413,3.419s.476,2.481,1.414,3.41c.937.928,2.087,1.399,3.418,1.399,1.274,0,2.372-.439,3.264-1.305.31-.313.315-.736.019-1.035l-.926-.987c-.184-.197-.385-.238-.521-.238-.118,0-.298.033-.48.188-.359.308-.779.457-1.283.457-.534,0-.967-.183-1.324-.558-.364-.383-.541-.834-.541-1.378,0-.554.176-1.014.539-1.406.353-.38.782-.565,1.313-.565.501,0,.922.159,1.287.485.16.161.352.199.48.199.124,0,.309-.035.492-.205l.957-.993c.185-.185.223-.385.22-.52-.002-.136-.048-.338-.252-.528-.91-.831-2.001-1.25-3.245-1.25h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M41.908,22.036c-.844-.836-1.266-1.854-1.266-3.055s.422-2.222,1.266-3.065,1.866-1.266,3.066-1.266c1.12,0,2.088.371,2.903,1.115.12.112.124.225.013.336l-.937.973c-.104.096-.204.096-.3,0-.456-.408-.996-.612-1.62-.612-.672,0-1.231.242-1.68.726-.448.484-.672,1.066-.672,1.746,0,.672.226,1.246.678,1.723.452.476,1.014.714,1.687.714.624,0,1.159-.192,1.607-.576.112-.096.216-.092.312.012l.936.996c.104.104.1.212-.012.324-.8.776-1.772,1.164-2.916,1.164-1.2,0-2.223-.418-3.066-1.254Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M44.975,14.65c1.12,0,2.088.371,2.903,1.115.12.112.124.225.013.336l-.937.973c-.052.048-.103.072-.153.072s-.099-.024-.147-.072c-.456-.408-.996-.612-1.62-.612-.672,0-1.231.242-1.68.726-.448.484-.672,1.066-.672,1.746,0,.672.226,1.246.678,1.723.452.476,1.014.714,1.687.714.624,0,1.159-.192,1.607-.576.054-.046.106-.069.156-.069.054,0,.106.027.157.081l.936.996c.104.104.1.212-.012.324-.8.776-1.772,1.164-2.916,1.164-1.2,0-2.223-.418-3.066-1.254-.844-.836-1.266-1.854-1.266-3.055s.422-2.222,1.266-3.065,1.866-1.266,3.066-1.266M44.975,14.15c-1.332,0-2.483.475-3.42,1.412-.937.937-1.412,2.087-1.412,3.419s.476,2.48,1.414,3.41c.937.928,2.087,1.399,3.418,1.399,1.274,0,2.372-.439,3.264-1.305.31-.313.315-.736.019-1.035l-.926-.987c-.184-.197-.385-.238-.521-.238-.118,0-.298.033-.48.188-.359.308-.779.457-1.283.457-.534,0-.967-.183-1.324-.558-.363-.383-.54-.834-.54-1.378,0-.555.176-1.015.539-1.406.353-.38.782-.565,1.313-.565.501,0,.922.159,1.287.485.16.161.352.199.48.199.124,0,.309-.036.492-.205l.957-.993c.185-.185.223-.385.22-.52-.002-.136-.048-.338-.252-.528-.91-.831-2.001-1.25-3.245-1.25h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
        <g>
          <path
            d="M50.41,22.942v-6.408h-1.716c-.064,0-.118-.022-.162-.066s-.066-.098-.066-.162v-1.308c0-.064.022-.118.066-.162s.098-.066.162-.066h5.328c.064,0,.117.022.162.066.043.044.066.098.066.162v1.308c0,.064-.023.118-.066.162-.045.044-.098.066-.162.066h-1.717v6.408c0,.056-.023.107-.072.155-.047.048-.1.072-.154.072h-1.441c-.055,0-.107-.024-.155-.072s-.072-.1-.072-.155Z"
            style={{ fill: '#000', strokeWidth: 0 }}
          />
          <path
            d="M54.022,14.77c.064,0,.117.022.162.066.043.044.066.098.066.162v1.308c0,.064-.023.118-.066.162-.045.044-.098.066-.162.066h-1.717v6.408c0,.056-.023.107-.072.155-.047.048-.1.072-.154.072h-1.441c-.055,0-.107-.024-.155-.072-.048-.048-.072-.1-.072-.155v-6.408h-1.716c-.064,0-.118-.022-.162-.066-.044-.044-.066-.098-.066-.162v-1.308c0-.064.022-.118.066-.162s.098-.066.162-.066h5.328M54.022,14.27h-5.328c-.195,0-.378.076-.516.213-.137.137-.213.32-.213.516v1.308c0,.195.076.378.213.516.137.137.32.213.516.213h1.216v5.908c0,.132.038.328.219.509.141.141.322.219.509.219h1.441c.189,0,.371-.079.511-.222.136-.133.215-.315.215-.505v-5.908h1.217c.195,0,.372-.072.512-.209.143-.146.217-.327.217-.52v-1.308c0-.192-.074-.374-.209-.512-.148-.145-.325-.217-.52-.217h0Z"
            style={{ fill: '#fff', strokeWidth: 0 }}
          />
        </g>
      </g>
    </svg>
  );
}

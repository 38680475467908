import { from as from$ } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import ScheduleShiftService from 'services/scheduleShift.service';
import * as scheduleShiftActions from '../actions/scheduleShift.actions';

export const getScheduleShiftEpic = action$ =>
  action$.pipe(
    filter(scheduleShiftActions.getScheduleShift.match),
    switchMap(action => from$(ScheduleShiftService.get(action.payload))),
    map(response => scheduleShiftActions.getScheduleShiftSuccess(response)),
  );

import { prop, uniqBy } from "ramda";

export default function getActiveAndSelectedDepartments(emsFacilityDepartments, departmentId) {
  const selectedEmsFacilityDepartment = emsFacilityDepartments
    .find(dept => dept.department?.id === departmentId);
  const selectedDepartment = selectedEmsFacilityDepartment?.department;

  const activeDepartments = uniqBy(
    prop('id'),
    emsFacilityDepartments
      .filter(dept => (!dept.inactive && dept.department && !dept.department.inactive) || (dept.department && dept.department.id === departmentId))
      .map(dept => dept.department),
  );

  return { selectedDepartment, activeDepartments };
}
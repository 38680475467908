import { concat as concat$, of as of$, throwError } from 'rxjs';
import { filter, map, mergeMap, switchMap, catchError } from 'rxjs/operators';
import {
  clearPickupDepartment,
  clearDestinationDepartment,
} from '../../components/PickupDestination/redux/actions/component.actions';
import Location from '../../values/location';
import * as agencyActions from '../actions/agency.actions';
import * as patientActions from '../actions/patient.actions';
import * as locationActions from '../actions/location.actions';
import * as mapActions from '../actions/map.actions';
import * as serviceActions from '../actions/service.actions';
import * as userActions from '../actions/user.actions';
import * as epicIntegrationActions from '../actions/epicIntegration.actions';
import { autoCompletePipe, errorPipe } from './epicsUtil';
import { getAgencies, getAgencyTransportReasons, getExternalRequestData } from './helpers/agency.helper';
import { getAgencyAddress } from './helpers/location.helper';
import { requestId } from './helpers/request.helper';
import { getExternalIdType } from './helpers/patient.helper';
import orderService from 'services/orders/orders.service';
import { isEditMode } from 'helpers/helpers';

export const getAgencyPickupLocationEpic = (action$, store) =>
  action$.pipe(
    filter(agencyActions.getPickupLocationByAgency.match),
    filter(() => !requestId(store) && store.value.agency.requesterAgencyId),
    switchMap(() => getAgencyAddress(store.value.agency.requesterAgencyId)),
    map(result => agencyActions.getPickupLocationSuccess(result)),
    errorPipe,
  );

export const getAgencyDestinationLocationEpic = (action$, store) =>
  action$.pipe(
    filter(agencyActions.getDestinationLocationByAgency.match),
    filter(() => !requestId(store) && store.value.agency.requesterAgencyId),
    switchMap(() => getAgencyAddress(store.value.agency.requesterAgencyId)),
    map(result => agencyActions.getDestinationLocationSuccess(result)),
    errorPipe,
  );

export const setAgencyTransportReasonsEpic = (action$, store) =>
  action$.pipe(
    filter(action => [agencyActions.fetchPlace.match, agencyActions.setAgency.match].some(match => match(action))),
    switchMap(() =>
      getAgencyTransportReasons({
        agencyId: store.value.agency.requesterAgencyId,
        typeName: 'medical_health_service',
      }),
    ),
    map(result => serviceActions.setAgencyTransportReasons(result)),
    errorPipe,
  );

export const selectPatientUpdateTransportReasons = (action$, store) =>
  action$.pipe(
    filter(patientActions.selectPatient.match),
    switchMap(() =>
      orderService.getTransportReasons(
        false,
        true,
        getExternalProviderIds(store),
        getExternalIdType(store.value.userAccount.selectedUserAccount.id),
      ),
    ),
    map(r => serviceActions.setAgencyTransportReasons(r)),
    // errorPipe,
  );

export const setAgencyPickupLocationEpic = (action$, store) =>
  action$.pipe(
    filter(agencyActions.getPickupLocationSuccess.match),
    filter(() => !store.value.epic.isEpicDataFetched),
    filter(action => action.payload[0]),
    mergeMap(action =>
      concat$(
        of$(mapActions.setMapPickupLocationByAgency(action.payload)),
        of$(locationActions.setAgencyPickupLocation(action.payload)),
        of$(locationActions.getPickupDepartments(Location.fromEmsFacility(action.payload[0].emsFacility).location)),
        of$(clearPickupDepartment()),
        of$(mapActions.setMapLocationSuccess(action.payload)),
      ),
    ),
  );

export const setAgencyDestinationLocationEpic = action$ =>
  action$.pipe(
    filter(agencyActions.getDestinationLocationSuccess.match),
    filter(action => action.payload[0]),
    mergeMap(action =>
      concat$(
        of$(mapActions.setMapDestinationLocationByAgency(action.payload)),
        of$(locationActions.setAgencyDestinationLocation(action.payload)),
        of$(
          locationActions.getDestinationDepartments(Location.fromEmsFacility(action.payload[0].emsFacility).location),
        ),
        of$(clearDestinationDepartment()),
        of$(mapActions.setMapLocationSuccess(action.payload)),
      ),
    ),
  );

export const setExternalRequestData = action$ =>
  action$.pipe(
    filter(agencyActions.fetchPlace.match),
    map(({ payload }) => payload.requesterAgencyGroup),
    filter(requesterAgencyGroup => requesterAgencyGroup?.epicTransferCenterIntegrationEnabled),
    filter(() => !isEditMode()),
    switchMap(requesterAgencyGroup =>
      getExternalRequestData(requesterAgencyGroup).pipe(
        map(result => epicIntegrationActions.fetchEpicDataSuccess(result)),
        catchError(error =>
          of$(epicIntegrationActions.fetchEpicDataFail(error)).pipe(mergeMap(() => throwError(() => error))),
        ),
      ),
    ),
    errorPipe,
  );

export const setMapLocationByCurrentUser = (action$, store) =>
  action$.pipe(
    filter(agencyActions.getPickupLocationSuccess.match),
    filter(() => !store.value.epic.isEpicDataFetched),
    filter(action => !action.payload[0]),
    map(() => mapActions.setMapLocationByCurrentUser(store.value)),
  );

export const setMapLocationByCurrentUserDropOff = (action$, store) =>
  action$.pipe(
    filter(agencyActions.getDestinationLocationSuccess.match),
    filter(action => !action.payload[0]),
    map(() => mapActions.setMapLocationByCurrentUser(store.value)),
  );

export const autoCompleteEpic = action$ =>
  action$.pipe(
    filter(agencyActions.queryAgencies.match),
    autoCompletePipe,
    switchMap(action => getAgencies(action.payload)),
    mergeMap(payload =>
      concat$(of$(agencyActions.fetchAgenciesSuccess(payload)), of$(agencyActions.setSearchAgenciesInProgress(false))),
    ),
    errorPipe,
  );

export const searchAgenciesInProgressEpic = action$ =>
  action$.pipe(
    filter(agencyActions.queryAgencies.match),
    autoCompletePipe,
    map(() => agencyActions.setSearchAgenciesInProgress(true)),
  );

export const autoSearchPreselectedPatient = (action$, store) => {
  return action$.pipe(
    filter(agencyActions.fetchPlace.match),
    filter(() => store.value.user.selectedPatientExternalId),
    switchMap(() =>
      of$(
        patientActions.selectPatientFromExternal({
          externalId: store.value.user.selectedPatientExternalId,
          clinic: { id: null },
        }),
      ),
    ),
  );
};

export const autoSelectPreselectedPatientIFSameRequesterAgencyGroup = (action$, store) =>
  action$.pipe(
    filter(userActions.selectPatientExternalId.match),
    switchMap(() =>
      of$(
        patientActions.selectPatientFromExternal({
          externalId: store.value.user.selectedPatientExternalId,
          clinic: { id: null },
        }),
      ),
    ),
  );

const getExternalProviderIds = store => {
  const externalIdType = getExternalIdType(store.value.userAccount.selectedUserAccount.id);
  const externalProviderIds = store.value.patient.selectedPatient.externalProviderIds;

  return externalIdType && externalProviderIds
    ? externalProviderIds.filter(e => e.externalIdType === externalIdType).map(e => e.externalId)
    : [];
};

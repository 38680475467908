import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as requestsActions from '../../redux/actions/requests.actions';
import * as formFlowActions from '../../redux/actions/formFlow.actions';
import RequestingAgency from '../RequestingAgencyWidget';
import Requester from '../PatientInfoBoxWidget/Requester';
import PickupDestination from '../PickupDestination';
import ServiceLevel from '../ServiceLevel';
import WaitAndReturn from '../WaitAndReturn';
import GetETA from '../GetETA';
import PatientInfoBox from '../PatientInfoBoxWidget';
import ErrorDialog from '../common/ErrorDialog';
import Summary from '../Summary';
import ConfirmPickupTime from '../ConfirmPickupTime';
import NewOrExisting from '../NewOrExisting';
import ConfirmationDialog from '../common/ConfirmationDialog';
import Toast from '../Toast';
import SimilarRequestFoundDialog from '../SimilarRequestFoundDialog';
import ProposedETAModal from '../ProposedETAModal';
import MedicalNecessityStepper from '../MedicalNecessityStepper';
import MissingFieldsModal from '../MissingFieldsModal';
import RequestShare from '../RequestShare';
import SaveButton from '../SaveButton';
import { requestShareDisplay } from '../../flags/display.flag';
import {
  patientDetailsDisabled,
  requestingAgencyDisabled,
  pickupDestinationDisabled,
  pickupDepartmentsDisabled,
  destinationDepartmentsDisabled,
  serviceLevelDisabled,
  patientDocumentsDisabled,
  patientCoverageDisabled,
  chiefComplaintDisabled,
  otherOptionOfChiefComplaintDisabled,
  notesDisabled,
  isWaitAndReturnDisabled,
} from '../../flags/disabled.flag';
import { isExternalDataLoading } from '../../flags/loading.flag';
import * as agencyActions from '../../redux/actions/agency.actions';
import PhysicianCertificationStatement from 'components/PhysicianCertificationStatement';
import AmbCircularProgress from 'components/common/AmbCircularProgress';

const FormContainer = props => {
  return props.isExternalDataLoading ? (
    <AmbCircularProgress
      propsStyles={{
        position: 'absolute',
        top: 250,
        left: 240,
        zIndex: 1,
      }}
    />
  ) : (
    <>
      {props.request.id && <Summary />}
      <RequestingAgency
        disabled={props.requestingAgencyDisabled}
        handleFillAgencyAddress={props.getPickupLocationByAgency}
      />
      <Requester />
      <PickupDestination
        disabled={props.pickupDestinationDisabled}
        pickupDepartmentsDisabled={props.pickupDepartmentsDisabled}
        destinationDepartmentsDisabled={props.destinationDepartmentsDisabled}
      />
      <ServiceLevel disabled={props.serviceLevelDisabled} />
      <WaitAndReturn disabled={props.isWaitAndReturnDisabled} willCallReturnEnabled />
      <GetETA />
      <PatientInfoBox
        patientDetailsDisabled={props.patientDetailsDisabled}
        patientDocumentsDisabled={props.patientDocumentsDisabled}
        patientCoverageDisabled={props.patientCoverageDisabled}
        chiefComplaintDisabled={props.chiefComplaintDisabled}
        otherOptionOfChiefComplaintDisabled={props.otherOptionOfChiefComplaintDisabled}
        notesDisabled={props.notesDisabled}
      />
      {props.requestShareDisplay && <RequestShare />}
      <SaveButton />
      <ErrorDialog />
      <Toast />
      <ConfirmPickupTime />
      <NewOrExisting />
      <ProposedETAModal />
      <MedicalNecessityStepper />
      <MissingFieldsModal />
      <ConfirmationDialog
        open={props.ETADialogOpen}
        onClose={props.closeNewETADialog}
        title={props.ETADialogTitle}
        cancel
      >
        <div>{props.ETADialogBody}</div>
      </ConfirmationDialog>
      <SimilarRequestFoundDialog />
      <PhysicianCertificationStatement />
    </>
  );
};

FormContainer.propTypes = {
  closeNewETADialog: PropTypes.func.isRequired,
  requestShareDisplay: PropTypes.bool.isRequired,
  patientDetailsDisabled: PropTypes.bool.isRequired,
  serviceLevelDisabled: PropTypes.bool.isRequired,
  destinationDepartmentsDisabled: PropTypes.bool.isRequired,
  pickupDepartmentsDisabled: PropTypes.bool.isRequired,
  pickupDestinationDisabled: PropTypes.bool.isRequired,
  requestingAgencyDisabled: PropTypes.bool.isRequired,
  patientDocumentsDisabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isExternalDataLoading: isExternalDataLoading(state),
    ETADialogOpen: state.formFlow.ETADialogOpen,
    ETADialogTitle: state.formFlow.ETADialogTitle,
    ETADialogBody: state.formFlow.ETADialogBody,
    similarRequest: state.formFlow.similarRequest,
    request: state.requests.request,
    loadedRequest: state.formFlow.loadedRequest,
    pollInProgress: state.eta.pollInProgress,
    requestShareDisplay: requestShareDisplay(state),
    patientDetailsDisabled: patientDetailsDisabled(state),
    requestingAgencyDisabled: requestingAgencyDisabled(state),
    pickupDestinationDisabled: pickupDestinationDisabled(state),
    pickupDepartmentsDisabled: pickupDepartmentsDisabled(state),
    destinationDepartmentsDisabled: destinationDepartmentsDisabled(state),
    serviceLevelDisabled: serviceLevelDisabled(state),
    patientDocumentsDisabled: patientDocumentsDisabled(state),
    patientCoverageDisabled: patientCoverageDisabled(state),
    chiefComplaintDisabled: chiefComplaintDisabled(state),
    otherOptionOfChiefComplaintDisabled: otherOptionOfChiefComplaintDisabled(state),
    notesDisabled: notesDisabled(state),
    isWaitAndReturnDisabled: isWaitAndReturnDisabled(state),
  };
}

export default connect(mapStateToProps, {
  ...requestsActions,
  ...formFlowActions,
  ...agencyActions,
})(FormContainer);

export const isExternalDataLoading = state => {
  const patientSearchFromEpicLaunchAllowed = window.location.href.indexOf('edit') === -1;
  const isExternalRequestLoaded = state.epic.isEpicDataFetched || state.epic.isEpicDataFetchFailed;
  const isDataLoading =
    patientSearchFromEpicLaunchAllowed &&
    state.agency.requesterAgencyGroup.epicTransferCenterIntegrationEnabled &&
    state.patient.selectedPatient &&
    !isExternalRequestLoaded;

  return isDataLoading;
};

import { createReducer } from 'redux-act';
import * as action from '../actions/scheduleShift.actions';
import ScheduledShift from '../../schemas/scheduleShift.schema';

const initialState = {
  id: null,
  serviceTypes: [],
};

const scheduleShiftReducer = createReducer(
  {
    [action.getScheduleShiftSuccess]: (state, payload) => {
      const schema = ScheduledShift.parse(payload);

      return {
        ...state,
        id: schema.id,
        serviceTypes: schema.service_types,
      };
    },
    [action.getScheduleShiftFailure]: state => ({
      ...state,
      id: null,
      serviceTypes: [],
    }),
  },
  initialState,
);

export default scheduleShiftReducer;

import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  FormLabel,
  FormHelperText,
  Typography,
  Paper,
} from '@mui/material';

import formStructure from './pcsForm.json';

const FormContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const StepperContainer = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  margin-bottom: 20px;
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const FormField = ({ field, value, onChange, formData, showError, dateError }) => {
  if (field.conditional) {
    const conditionalValue = formData[field.conditional.field];
    if (Array.isArray(conditionalValue)) {
      if (!conditionalValue.includes(field.conditional.value)) {
        return null;
      }
    } else if (field.conditional.value === true) {
      if (!conditionalValue || conditionalValue.length === 0) {
        return null;
      }
    } else if (conditionalValue !== field.conditional.value) {
      return null;
    }
  }

  const isFieldInvalid = showError && field.required && (value === undefined || value === '' || value === null);

  const commonProps = {
    error: isFieldInvalid || (field.type === 'date' && dateError),
    helperText: isFieldInvalid
      ? 'This field is required'
      : field.type === 'date' && dateError
      ? dateError
      : field.helperText,
    required: field.required,
  };

  const HelperText = styled(Typography)`
    font-style: italic;
    font-weight: 500;
    margin-top: 4px;
  `;

  const CharCounter = styled(Typography)(({ theme, isoverlimit }) => ({
    textAlign: 'right',
    marginTop: theme.spacing(0.5),
    color: isoverlimit ? theme.palette.error.main : 'inherit',
  }));

  switch (field.type) {
    case 'text':
    case 'date':
      return (
        <>
          <TextField
            {...commonProps}
            fullWidth
            id={field.id}
            label={field.label}
            type={field.type}
            value={value || ''}
            onChange={e => onChange(field.id, e.target.value)}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: field.maxDate ? new Date().toISOString().split('T')[0] : undefined,
            }}
          />
          {!isFieldInvalid && !dateError && field.helperText && (
            <HelperText variant="caption">{field.helperText}</HelperText>
          )}
        </>
      );
    case 'radio':
      return (
        <div>
          <FormLabel error={isFieldInvalid}>{field.label}</FormLabel>
          <RadioGroup name={field.id} value={value} onChange={e => onChange(field.id, e.target.value)}>
            {field.options.map(option => (
              <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
          </RadioGroup>
          {isFieldInvalid && <FormHelperText error>{commonProps.helperText}</FormHelperText>}
          {!isFieldInvalid && field.helperText && <HelperText variant="caption">{field.helperText}</HelperText>}
        </div>
      );
    case 'checkboxgroup':
      return (
        <FormGroup>
          <Typography variant="subtitle1">{field.label}</Typography>
          {field.helperText && (
            <Typography variant="subtitle2" style={{ fontStyle: 'italic', marginBottom: '10px' }}>
              {field.helperText}
            </Typography>
          )}
          {field.options.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={Array.isArray(value) ? value.includes(option.value) : false}
                  onChange={e => {
                    const newValue = e.target.checked
                      ? [...(Array.isArray(value) ? value : []), option.value]
                      : (Array.isArray(value) ? value : []).filter(v => v !== option.value);
                    onChange(field.id, newValue);
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      );
    case 'checkbox':
      return (
        <FormGroup>
          <label>{field.label}</label>
          {field.options.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={Array.isArray(value) ? value.includes(option.value) : value === option.value}
                  onChange={e => {
                    if (Array.isArray(value)) {
                      const newValue = e.target.checked
                        ? [...value, option.value]
                        : value.filter(v => v !== option.value);
                      onChange(field.id, newValue);
                    } else {
                      onChange(field.id, e.target.checked ? option.value : '');
                    }
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      );
    case 'textarea':
      const charCount = value ? value.length : 0;
      const isOverLimit = field.maxChars && charCount > field.maxChars;

      const handleTextareaChange = e => {
        const newValue = e.target.value;
        const lines = newValue.split('\n');
        if (lines.length <= field.maxLines) {
          onChange(field.id, newValue);
        } else {
          onChange(field.id, lines.slice(0, field.maxLines).join('\n'));
        }
      };

      return (
        <>
          <FormLabel htmlFor={field.id}>{field.label}</FormLabel>
          <TextField
            {...commonProps}
            fullWidth
            id={field.id}
            multiline
            rows={field.maxLines || 1}
            value={value}
            onChange={handleTextareaChange}
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: field.maxChars,
            }}
          />
          {field.maxChars && (
            <CharCounter isoverlimit={isOverLimit ? 1 : 0}>
              {charCount}/{field.maxChars} characters
            </CharCounter>
          )}
        </>
      );
    case 'static':
      return <p>{field.content}</p>;
    default:
      return null;
  }
};

const formatDate = date => {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
};

const ReviewStep = ({ formData, formStructure }) => {
  const MultiLineValue = styled(Typography)`
    white-space: pre-wrap;
    word-break: break-word;
  `;

  return (
    <div>
      <h2>Review Your Answers</h2>
      {formStructure.sections.map(section => (
        <Paper key={section.title} elevation={0} style={{ padding: '16px', marginBottom: '16px' }}>
          <Typography variant="h6" gutterBottom>
            {section.title}
          </Typography>
          {section.fields.map(field => {
            const value = formData[field.id];
            if (value) {
              return (
                <div key={field.id} style={{ marginBottom: '8px' }}>
                  <Typography variant="subtitle1" component="strong">
                    {field.label}
                  </Typography>
                  <MultiLineValue>
                    {(() => {
                      if (field.type === 'date') {
                        return formatDate(value);
                      } else if (
                        field.type === 'checkbox' ||
                        field.type === 'radio' ||
                        field.type === 'checkboxgroup'
                      ) {
                        if (Array.isArray(value)) {
                          return value.map(v => field.options.find(option => option.value === v)?.label).join(', ');
                        } else {
                          return field.options.find(option => option.value === value)?.label || value;
                        }
                      } else if (field.type === 'textarea') {
                        return value; // This will preserve line breaks
                      } else {
                        return value;
                      }
                    })()}
                  </MultiLineValue>
                </div>
              );
            }
            return null;
          })}
        </Paper>
      ))}
    </div>
  );
};

const validateSection = (section, formData, dateErrors) => {
  return section.fields.every(field => {
    if (field.required) {
      if (
        field.conditional &&
        field.conditional.field &&
        formData[field.conditional.field] !== field.conditional.value
      ) {
        return true;
      }
      if (formData[field.id] == null || formData[field.id] === undefined || formData[field.id] === '') {
        return false;
      }
      if (field.type === 'date' && dateErrors[field.id]) {
        return false;
      }
    }
    return true;
  });
};

const PhysicianCertificationStatement = ({ onSubmit, onCancel, formData, setFormData, ...props }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [showError, setShowError] = useState(false);
  const [dateErrors, setDateErrors] = useState({});

  const handleNext = () => {
    const isValid = validateSection(currentSection, formData, dateErrors);
    setShowError(!isValid);
    if (isValid) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setShowError(false);
  };

  const handleFieldChange = (id, value) => {
    setFormData(prevData => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleDateChange = (id, newValue) => {
    if (isNaN(Date.parse(newValue))) {
      setDateErrors(prevErrors => ({ ...prevErrors, [id]: 'Please enter a valid date' }));
      return;
    }

    const field = formStructure.sections.flatMap(section => section.fields).find(f => f.id === id);
    if (field && field.maxDate) {
      const maxDate = new Date();
      const selectedDate = new Date(newValue);
      if (selectedDate > maxDate) {
        setDateErrors(prevErrors => ({ ...prevErrors, [id]: 'Date cannot be in the future' }));
        return;
      }
    }

    setDateErrors(prevErrors => ({ ...prevErrors, [id]: '' }));
    handleFieldChange(id, newValue);
  };

  const handleSubmit = e => {
    const parsedData = parseFormData(formData);

    const signatureField = {
      name: 'signature',
      type: 'text',
      value: `Electronically signed by ${formData.signerName}, at ${formData.signatureDate}`,
    };

    parsedData.formFields.push(signatureField);

    onSubmit(parsedData);
  };

  const handleCancel = () => {
    onCancel();
  };

  const parseFormData = data => {
    return {
      formFields: Object.entries(data).flatMap(([name, value]) => {
        const field = formStructure.sections.flatMap(section => section.fields).find(f => f.id === name);

        if (field && field.type === 'checkboxgroup') {
          return field.options
            .filter(option => Array.isArray(value) && value.includes(option.value))
            .map(option => ({
              name: option.value,
              type: 'checkbox',
              value: 'true',
            }));
        }

        let formattedValue = value;
        if (field && field.type === 'date' && value) {
          formattedValue = formatDate(value);
        }

        return [
          {
            name,
            type: field ? field.type : 'text',
            value: Array.isArray(formattedValue) ? formattedValue.join(', ') : formattedValue,
          },
        ];
      }),
    };
  };

  const currentSection = formStructure.sections[activeStep];
  const isLastStep = activeStep === formStructure.sections.length;

  return (
    <FormContainer>
      <form>
        <StepperContainer>
          <Stepper activeStep={activeStep} alternativeLabel>
            {formStructure.sections.map((section, index) => (
              <Step key={index}>
                <StepLabel>{section.title}</StepLabel>
              </Step>
            ))}
            <Step>
              <StepLabel>Review</StepLabel>
            </Step>
          </Stepper>
        </StepperContainer>

        {!isLastStep ? (
          <>
            <SectionTitle>{currentSection.title}</SectionTitle>
            {currentSection.description && <p>{currentSection.description}</p>}

            <Grid container spacing={2}>
              {currentSection.fields.map(field => (
                <Grid item xs={field.gridWidth || 12} key={field.id}>
                  <FieldContainer>
                    <FormField
                      field={field}
                      value={formData[field.id]}
                      onChange={field.type === 'date' ? handleDateChange : handleFieldChange}
                      formData={formData}
                      showError={showError}
                      dateError={dateErrors[field.id]}
                    />
                  </FieldContainer>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <ReviewStep formData={formData} formStructure={formStructure} />
        )}

        <ActionContainer>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <div>
            <Button onClick={handleCancel} style={{ marginRight: '10px' }}>
              Cancel
            </Button>
            {isLastStep ? (
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Submit
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Continue
              </Button>
            )}
          </div>
        </ActionContainer>
      </form>
    </FormContainer>
  );
};

export default PhysicianCertificationStatement;

import schema from 'schm';

export const ScheduleShiftEMT = schema({
  employee_number: String,
});

export const ScheduleShiftEquipment = schema({
  id: Number,
  name: String,
  display_name: String,
  display_order: Number,
  description: String,
  actor_id: Number,
  created_at: Date,
  updated_at: Date,
  default: Boolean,
  pin_trip_to_shift: Boolean,
});

export const ScheduleShiftLocation = schema({
  lat: Number,
  lng: Number,
  type: String,
  timestamp: String,
});

export const ScheduleShiftSchedulingType = schema({
  id: Number,
  name: String,
  display_name: String,
  display_order: Number,
  description: String,
  inactive_at: Date,
  inactive_by_id: Number,
  actor_id: Number,
});

const ScheduleShift = schema({
  id: Number,
  epro_name: String,
  unit: String,
  start_time: Date,
  end_time: Date,
  start_at: Date,
  end_at: Date,
  required_emts: Number,
  station: Number,
  emts: [ScheduleShiftEMT],
  service_types: [String],
  equipment: [ScheduleShiftEquipment],
  location: ScheduleShiftLocation,
  scheduling_type: ScheduleShiftSchedulingType,
});

export default ScheduleShift;

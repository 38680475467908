import React from 'react';
import PropTypes from 'prop-types';
import AmbGrid from 'components/common/AmbGrid';
import SuggestionsInput from '../../common/SuggestionsInput';
import AmbCheckbox from '../../common/AmbCheckbox';
import AmbDropdown from '../../common/AmbDropdown';
import AmbDropdownTimePicker from '../../common/AmbDropdownTimePicker/AmbDropdownTimePicker';
import times from './pickupDestinationTimes';
import RoomNumber from '../RoomNumber';

const Location = ({
  autoFocus,
  department,
  departmentOptions,
  departmentOptionsDisabled,
  disabled,
  error,
  estTimePlaceholder,
  handleDepartmentChange,
  helperText,
  inputId,
  inputPlaceholder,
  inputValue,
  isResidence,
  isResidenceDisabled,
  items,
  loading,
  onInputClick,
  onInputValueChange,
  onSelectWaitTime,
  propsStyles,
  residenceHandleChange,
  roomFieldName,
  selectedItem,
  showEstTime,
  subLabel,
  suggestionFieldName,
  waitTimeValue,
  isDestinationConfirmed,
  destinationConfirmationRequired,
  destinationConfirmHandleChange,
}) => (
  <>
    <AmbGrid>
      <AmbGrid style={{ marginTop: 23, flexBasis: '77%' }}>
        <SuggestionsInput
          inputValue={inputValue}
          selectedItem={selectedItem}
          helperText={helperText}
          onInputValueChange={onInputValueChange}
          onInputClick={onInputClick}
          items={items}
          inputId={inputId}
          inputPlaceholder={inputPlaceholder}
          autoFocus={autoFocus}
          autoComplete="new-password"
          required
          loading={loading}
          name={suggestionFieldName}
          error={error}
          disabled={disabled || null}
          subLabel={subLabel}
        />
      </AmbGrid>
      <AmbGrid>
        <AmbCheckbox
          checked={isResidence}
          inputId={`${inputId}-residence-checkbox`}
          handleChange={residenceHandleChange}
          label={
            <>
              Residence
              <br />
              or Scene
            </>
          }
          propsStyles={{
            marginTop: 40,
            paddingLeft: 5,
            marginRight: 0,
          }}
          disabled={isResidenceDisabled || null}
        />
      </AmbGrid>
    </AmbGrid>
    {destinationConfirmationRequired && (
      <AmbCheckbox
        checked={isDestinationConfirmed}
        inputId={`${inputId}-destination-confirmed-checkbox`}
        handleChange={destinationConfirmHandleChange}
        label={
          <>
            I have verified the destination address with the receiving facility<span>&thinsp;*</span>
          </>
        }
        propsStyles={{
          marginLeft: '-10px',
        }}
      />
    )}
    <AmbGrid style={{ marginTop: 10, gap: '5px', justifyContent: 'space-between' }}>
      <div style={{ display: 'inline-flex', flex: '2' }}>
        <AmbDropdown
          placeholder="Department"
          options={departmentOptions.map(c => ({
            key: c.id,
            value: c.id,
            name: c.name,
          }))}
          inputId={`${inputId}-select-department`}
          containerStyles={{ width: '100%' }}
          onSelect={handleDepartmentChange}
          value={department || ''}
          disabled={disabled || departmentOptionsDisabled || null}
          selectInputStyles={{ minWidth: 145 }}
        >
          <option
            key="placeholder"
            value=""
            style={{
              color: '#919799',
            }}
          />
        </AmbDropdown>
      </div>
      <AmbGrid style={{ flex: 1, marginTop: 1 }}>
        <RoomNumber name={roomFieldName} inputId={`${inputId}-room-number`} />
      </AmbGrid>
      {showEstTime && (
        <AmbGrid style={{ marginTop: 7 }}>
          <AmbDropdownTimePicker
            propsStyles={propsStyles}
            times={times}
            placeholder={estTimePlaceholder}
            onSelect={onSelectWaitTime}
            value={waitTimeValue}
            disabled={disabled || null}
          />
        </AmbGrid>
      )}
    </AmbGrid>
  </>
);

Location.propTypes = {
  inputValue: PropTypes.string.isRequired,
  selectedItem: PropTypes.shape().isRequired,
  helperText: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired,
  onInputClick: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  suggestionFieldName: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  propsStyles: PropTypes.shape().isRequired,
  estTimePlaceholder: PropTypes.string.isRequired,
  onSelectWaitTime: PropTypes.func.isRequired,
  waitTimeValue: PropTypes.string.isRequired,
  roomFieldName: PropTypes.string.isRequired,
  isResidence: PropTypes.bool.isRequired,
  isResidenceDisabled: PropTypes.bool.isRequired,
  residenceHandleChange: PropTypes.func.isRequired,
  department: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
};

Location.defaultProps = {
  department: '',
  helperText: '',
  autoFocus: false,
  disabled: false,
};

export default Location;

import ApiService from './api.service';

export class ScheduleShiftService {
  static async get(id) {
    const url = `${window.ambulnzConfig.tenantedRestApiUrl}/ScheduledShifts/${id}`;
    const response = await ApiService.get(url);
    return response.json();
  }
}

export default ScheduleShiftService;

import { filter, map, switchMap } from 'rxjs/operators';
import {
  careProvidedAtValid,
  hasTransportReasons,
  isSinglePrimaryCareSelected,
  isWeightHeightRequired,
} from '../../../flags/service.flag';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as formFlowActions from '../../../redux/actions/formFlow.actions';
import * as infoboxActions from '../../../redux/actions/infobox.actions';
import * as locationActions from '../../../redux/actions/location.actions';
import * as mapActions from '../../../redux/actions/map.actions';
import * as patientActions from '../../../redux/actions/patient.actions';
import { setReturnRequestCanBeActivatedByPatient } from '../../../redux/actions/requests.actions';
import * as serviceActions from '../../../redux/actions/service.actions';
import RequestService from '../../../services/request.service';
import { isLocationValid } from '../../../validators/location.validator';
import {
  careProvidedAtChbChange,
  careProvidedAtDescChange,
} from '../../ServiceLevel/CareProvidedAtReceivingFacility/actions';

const isRequesterValid = state =>
  state.infobox.requesterFirstName &&
  state.infobox.requesterLastName &&
  state.infobox.requesterPhone &&
  state.infobox.requesterPhone.length === 14;

const isServiceLevelValid = state =>
  !state.service.questionMode && state.service.serviceId && careProvidedAtValid(state.requests);

function isWillCallReturnValid(state) {
  if (!state.requests.canReturnRequestBeActivatedByPatient) {
    return true;
  }

  return (
    state.infobox.patientFirstName &&
    state.infobox.patientLastName &&
    state.infobox.patientBirthday &&
    state.patient.phone &&
    state.patient.phone.length === 14
  );
}

const isWeightHeightValid = state => {
  const { patientWeight, patientHeight } = state.infobox;
  return isWeightHeightRequired(state) ? !!patientWeight && !!patientHeight : true;
};

const isValidForETA = state =>
  !state.requests.loadedWillCall &&
  !(
    isLocationValid(state) &&
    (isRequesterValid(state) || state.service.isLightsAndSirens) &&
    isServiceLevelValid(state) &&
    isWillCallReturnValid(state) &&
    hasTransportReasons(state) &&
    isSinglePrimaryCareSelected(state) &&
    isWeightHeightValid(state)
  );

export const deleteSchedule = (action$, store) =>
  action$.pipe(
    filter(etaActions.setDateTime.match),
    filter(() => store.value.offers.offers.some(offer => offer.offerStatus.name === 'accepted')),
    switchMap(() => {
      const { requests } = store.value;
      return RequestService.deleteSchedule(requests.request.id);
    }),
  );

// eslint-disable-next-line import/prefer-default-export
export const enableETAButtonEpic = (action$, store) =>
  action$.pipe(
    filter(action =>
      [
        mapActions.setMapLocationSuccess.match,
        etaActions.setDateTime.match,
        etaActions.setAppmtTime.match,
        locationActions.clearPickupLocation.match,
        locationActions.clearDestinationLocation.match,
        locationActions.selectPickupLocationSuccess.match,
        locationActions.selectDestinationLocationSuccess.match,
        locationActions.queryPickupLocations.match,
        locationActions.queryDestinations.match,
        locationActions.setDestinationConfirmation.match,
        formFlowActions.setValid.match,
        serviceActions.setQuestionMode.match,
        infoboxActions.setRequesterInfo.match,
        infoboxActions.setRequesterPhone.match,
        serviceActions.setAnswer.match,
        careProvidedAtChbChange.match,
        careProvidedAtDescChange.match,
        setReturnRequestCanBeActivatedByPatient.match,
        infoboxActions.setPatientDetails.match,
        infoboxActions.setPatientInfo.match,
        patientActions.setPhone.match,
        serviceActions.mobilityChange.match,
        serviceActions.patientTypeChange.match,
        serviceActions.equipmentChange.match,
        serviceActions.serviceTypeChange.match,
      ].some(match => match(action)),
    ),
    map(() => etaActions.setETAButtonDisabled(isValidForETA(store.value))),
  );

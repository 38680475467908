export const CallType = {
  DEFAULT: 1000,
};

export const PatientType = {
  NICU: 'NICU',
  PEDS: 'PEDS',
  Adult: 'Adult',
};

export const Calc = {
  MILES: 1609.344,
};

export const ViewStatus = {
  CREATE: 0,
  EDIT: 1,
  LIST: 2,
};

export const RequestStatus = {
  PENDING_FOR_ASSIGNMENT: 300,
  ON_HOLD: 250,
  CREATED: 0,
  SCHEDULED: 25,
  WILL_CALL: 50,
  TURNDOWN: 100,
  APPROVAL_NEEDED: 200,
  NEEDS_DISPATCHER_ACTION: 250,
  NEEDS_ASSIGNMENT: 275,
  ASSIGNMENT_IS_ACTIVE: 300,
  PRE_ASSIGNED: 325,
  OFFERED: 350,
  AT_WORK: 400,
  CANCELLED: 500,
  COMPLETED: 900,
};

export const RequestType = {
  ON_DEMAND: 100,
  PRE_SCHEDULED: 200,
  PRE_SCHEDULED_RETURN: 300,
};

export const MOBILITY_NAMES = {
  walk: 'walk',
  wheelchair: 'wheelchair',
  bedbound: 'bedbound',
};

export const SERVICE_TYPE_NAMES = {
  BLS: 'BLS',
  ALS: 'ALS',
  CCT: 'CCT',
  Bariatric: 'Bariatric',
  Gurney: 'Gurney',
  Curbside: 'Curbside',
  WAV: 'WAV',
  NICU_BLS: 'NICU BLS',
  NICU_ALS: 'NICU ALS',
  NICU_CCT: 'NICU CCT',
  PEDS_BLS: 'PEDS BLS',
  PEDS_ALS: 'PEDS ALS',
  PEDS_CCT: 'PEDS CCT',
};

export const RelayCommands = {
  SHIFT_SESSION_INFO: 'shift-session-info',
  REQUEST_UPDATED: 'request-updated',
  REQUEST_CANCELED: 'request-canceled',
  REQUEST_UNCANCELED: 'request-uncanceled',
  LOCATION_UPDATED: 'location-updated',
  APPROVAL_NEEDED: 'approval-needed',
  VEHICLE_REQUESTED: 'vehicle-requested',
  VEHICLE_ASSIGNED: 'vehicle-assigned',
  VEHICLE_REJECTED: 'vehicle-rejected',
  VEHICLE_ASSIGNMENT_REQUEST: 'vehicle-assignment-request',
  VEHICLE_ASSIGNMENT_REQUEST_FOR_REQUESTERS: 'vehicle-assignment-request-for-requesters',
  VEHICLE_ASSIGNMENT_FAILED: 'vehicle-assignment-failed', // sent when the Dispatcher or manual assignment has failed.
  VEHICLE_DISCONNECT: 'vehicle-disconnected',
  VEHICLE_CONNECT: 'vehicle-connected',
  JOIN_ROOM: 'join',
  JOIN_SUCCESSFUL: 'join_successful',
  LEAVE_ROOM: 'leave',
  LEAVE_SUCCESSFUL: 'leave_successful',
  LEAVE_ALL_ROOM: 'leave_all',
  LEAVE_ALL_SUCCESSFUL: 'leave_all_successful',
  SEND_CACHED_LOCATIONS: 'send-cached-locations',
  SEND_BETTER_URL: 'send-better-url',
  BETTER_URL: 'better-url',
  WHO_AM_I: 'who-am-i', // set the current app and user
  REQUEST_RESET: 'request-reset',
  OPTIMIZER_COSTS: 'optimizer-costs',
  OPTIMIZER_SWAP: 'optimizer-swap',
  SESSION_UPDATED: 'session-updated',
  CONFIG_RELOAD: 'config-reload',
  SHIFT_UPDATED: 'shift-updated',
  VEHICLE_UPDATED: 'vehicle-updated',
  UPLOAD_LOG: 'upload-log',
  START_SCHEDULED_TRIP: 'start-scheduled-trip',
  SEND_ZIRMED_ELIGIBILITY_REPORT: 'send-zirmed-eligibility-report',
  SCHEDULE_UPDATED: 'schedule.state_update',
  REQUEST_STATUS_UPDATE: 'request.status_update',
  REQUEST_LOCATION_UPDATED: 'request.location_update',
  ELIGIBILITY_REPORT_UPDATE_EVENT: 'request.eligibility_update',
};

export const LegacyRelayRooms = {
  SHIFT_SESSION_ROOM: 'ShiftSessionRoom',
  SHIFT_ROOM: 'ShiftRoom',
  REQUEST_ROOM: 'RequestRoom',
  REQUESTER_ROOM: 'RequesterRoom',
  DISPATCHER_ROOM: 'DispatchersRoom',
  ALL_ROOM: 'AllRoom',
  AVAILABLE_ROOM: 'AvailableRoom',
  ACTIVE_ROOM: 'ActiveRoom',
  CONNECTIONS_ROOM: 'ConnectionsRoom',
  SYSTEM_ROOM: 'SystemRoom',
  REST_API_INSTANCE_ROOM: 'RestApiRoom',
  ALL_ROOM_V2: 'AllRoom_v2',
  INSTALLATION_ROOM: 'Installation',
  ELIGIBILITY_ROOM: 'Eligibility-Report',
};

export const RelayRooms = {
  ACCOUNT_EVENTS: 'account_events',
};

export const SYSSTRING_TYPE = {
  FACILITY_TYPES: 2,
  USER_TYPES: 3,
  PERMISSION_GROUPS_TYPES: 4,
  PERMISSION_CODES: 5,
  PAYER_TYPES: 6,
  REQUEST_STATUES: 7,
  SHIFTSESSION_STATUES: 8,
  COMMENT_TYPES: 9,
  REQUEST_ACTION_CODES: 10,
  GENDER_TYPES: 13,
  CALL_TYPES: 14,
  CHIEF_COMPLAINTS: 15,
};

export const stateCodes = [
  '',
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const PriorityType = {
  DEFAULT: 5,
};

export const COMMENTS = {
  TYPES: {
    REQUEST: 1,
    CANCEL: 2,
    SHIFT_SESSION_ON_HOLD: 3,
    SHIFT_SESSION_CLEAR_HOLD: 4,
    SHIFT_LOCK: 5,
    SHIFT_UNLOCK: 6,
    REQUEST_PICKUP_UPDATE: 7,
  },
  REQUEST_ENTITY: 'request',
  SHIFT_LOCK_ENTITY: 'shiftLock',
};

export const GENDER_TYPES = {
  2: 'Female Attendant',
};

export const CHIEF_COMPLAINT = {
  TYPES: {
    STANDARD: 1,
    NON_STANDARD: 2,
    Standard: 1,
    'Non Standard': 2,
  },
};

export const SCHEDULE_STATE = {
  TO_PICK_UP: 'to_pick_up',
  AT_PICK_UP: 'at_pick_up',
  PATIENT_PICKED_UP: 'patient_picked_up',
  TO_DROP_OFF: 'to_drop_off',
  AT_DROP_OFF: 'at_drop_off',
  NOT_RESERVED: 'not_reserved',
  RESERVED: 'reserved',
  ACTIVE: 'active',
  ALL_CLEAR: 'all_clear',
  CANCELLED: 'cancelled',
  TO_APPOINTMENT: 'to_appointment',
  AT_APPOINTMENT: 'at_appointment',
  MADE_PATIENT_CONTACT: 'made_patient_contact',
  APPOINTMENT_END: 'appointment_end',
};

export const AMBULNZ_THEME = 'ambulnz';
export const DARA_THEME = 'dara';

export const PROGRAM_CODES = {
  UBER: 'ubr',
  STANDING_ORDER: 'sto',
  MHS: 'MHS',
  GEOSERVICE_TRIP_PLANNER: 'gtp',
  GEOSERVICE_TRIP_PLANNER_DISPATCHER: 'gtd',
  MULTIDAY_TRIP_PLANNER: 'mtp',
};

export const ETA_TYPES = {
  APPOINTMENT: 'requestedAppointmentTime',
  PICKUP: 'requestedArrivalTime',
};

export const REQUESTER_OPTIONS_DEFAULTS = {
  insuranceInformationShow: true,
  insuranceInformationRequired: true,
  uberCoverageRequired: false,
  doNotSetCoverageFromPatientSearch: false,
  canRequestBeActivatedByPatient: false,
  defaultValueForPatientActivation: false,
  patientAddressRequired: false,
  priorityType: PriorityType.DEFAULT,
};

export const POLL_IN_PROGRESS_REASONS = {
  SCHEDULING_UNAVAILABLE: 'Scheduling Temporarily Unavailable',
  ETA_REJECTED: 'You Rejected All ETAs',
  APPMT_CANNOT_BE_MET: 'Appointment Time cannot be met',
  APPMT_REJECT_REASON:
    'The Appointment Time is too soon and cannot be met. Please change the request to an immediate pickup in order to get an ETA.',
  NO_RESOLVABLE_CONFLICTS: 'Please complete the request while we schedule it manually.',
  NO_AUTO_ETA: 'Sorry, we could not automatically schedule your request',
};

export const USER_TYPES = {
  DISPATCHER: 5,
  REQUESTER: 3,
};

export const DEFAULT_SHIFT_MSG_MAP = {
  [USER_TYPES.DISPATCHER]: {},
  [USER_TYPES.REQUESTER]: {},
  noEta: {},
};

export const PATIENT_SIGNATURE_TYPE = 'Patient Signature';

export const REQUEST_THERAPIST_EQUIPMENT = {
  VENTILATOR: 1,
  CPAP: 2,
  BIPAP: 3,
};

export const SCHEDULE_TEMPLATE_NAME = {
  MOBILE_HEALTH_TRIP: 'mobile_health_trip',
};

export const REQUEST_TYPE = {
  MOBILE_HEALTH_SERVICE: 'mobile_health_service',
  MEDICAL_TRANSPORT: 'medical_transport',
  POSTING: 'posting',
  TELEHEALTH: 'telehealth',
};

export const MOBILE_HEALTH_MULTI_TRIP_CONFIG = {
  DEFAULT_DAYS: 5,
};

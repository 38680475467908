import * as infoboxActions from '../actions/infobox.actions';
import * as epicIntegrationActions from '../actions/epicIntegration.actions';
import * as eligibilityActions from '../actions/eligibility.actions';
import * as serviceActions from '../actions/service.actions';
import { payer } from '../../schemas/infobox.schema';
import createMiddleware from './helper';
import { parseCoverage } from '../../parsers/payer.parser';
import { getNullCoverageFields } from '../../parsers/infobox.parser';

export const reorderPayersMdl = createMiddleware(infoboxActions.reorderPayer, ({ dispatch, payload, getState }) => {
  const { infobox, eligibility } = getState();
  const coverages = Object.values(infobox.coverages);
  const { swapWithCoordinationTypeId, coordinationTypeId } = payload;
  const coverageToSwap = coverages.find(cov => cov.coordinationTypeId === coordinationTypeId);
  const coverageToSwapWith = coverages.find(cov => cov.coordinationTypeId === swapWithCoordinationTypeId);

  // Update payers
  dispatch(
    infoboxActions.setPayerInfo({
      ...coverageToSwap,
      coordinationTypeId: coverageToSwapWith.coordinationTypeId,
    }),
  );
  dispatch(
    infoboxActions.setPayerInfo({
      ...coverageToSwapWith,
      coordinationTypeId: coverageToSwap.coordinationTypeId,
    }),
  );

  // Update eligibilities
  dispatch(
    eligibilityActions.setInfo({
      ...eligibility.eligibilities[coverageToSwap.coordinationTypeId],
      coordinationTypeId: coverageToSwapWith.coordinationTypeId,
    }),
  );
  dispatch(
    eligibilityActions.setInfo({
      ...eligibility.eligibilities[coverageToSwapWith.coordinationTypeId],
      coordinationTypeId: coverageToSwap.coordinationTypeId,
    }),
  );

  dispatch(infoboxActions.autoSavePayerInfo());
});

const selectPopulatedPayerMdl = createMiddleware(infoboxActions.selectPopulatedPayer, ({ dispatch, payload }) => {
  const coverage = parseCoverage(
    {
      payer: payload.response,
    },
    {},
    window.ambulnzConfig.coordinationTypes.PRIMARY,
  );

  delete coverage.isRequired;

  dispatch(
    infoboxActions.setPayerInfo({
      coordinationTypeId: window.ambulnzConfig.coordinationTypes.PRIMARY,
      ...coverage,
    }),
  );
});

const selectPayerFromSearchMdl = createMiddleware(
  infoboxActions.selectPayerFromSearch,
  ({ dispatch, payload, getState }) => {
    const { item, coordinationTypeId } = payload;

    const changeObj = {
      coordinationTypeId,
      payerInput: item.payerInput,
      payerName: item.payerName,
      payerQuery: item.payerQuery,
      payer: payer.parse(item.payer),
    };

    Object.assign(changeObj, getNullCoverageFields());

    dispatch(infoboxActions.setPayerInfo(changeObj));
    dispatch(infoboxActions.autoSavePayerInfo());
  },
);

const populatedPayerResponseMdl = createMiddleware(infoboxActions.populatedPayerResponse, ({ dispatch, payload }) => {
  const coverage = parseCoverage(
    {
      payer: payload.response,
    },
    {},
    payload.coordinationTypeId,
  );

  dispatch(
    infoboxActions.setPayerInfo({
      coordinationTypeId: payload.coordinationTypeId,
      ...coverage,
    }),
  );
});

const updatePolicyActivationMdl = createMiddleware(infoboxActions.setPayerInfo, ({ getState, payload }) => {
  console.log(payload);
});

const updateServiceTypeOnEpicFetchSuccess = createMiddleware(
  epicIntegrationActions.fetchEpicDataSuccess,
  ({ dispatch, payload, getState }) => {
    if (payload.error) {
      return;
    }

    const { serviceTypes } = payload;

    if (!serviceTypes || !serviceTypes.length || !serviceTypes[0]?.id) {
      return;
    }

    const state = getState();
    const callTypeToSelect =
      window.ambulnzConfig.constants.DEFAULT_FHIR_TRANSPORT_REASON ||
      window.ambulnzConfig.callTypes.URGENT ||
      window.ambulnzConfig.callTypes.APPOINTMENT;

    if (!callTypeToSelect) {
      return;
    }

    dispatch(serviceActions.setAnswersForEmergency());
    dispatch(serviceActions.setCallType(callTypeToSelect));
    dispatch(serviceActions.setQuestionMode(false));
    dispatch(serviceActions.mobilityChange(state.service.mobilities.bedbound));
    dispatch(serviceActions.serviceTypeChange(serviceTypes[0].id));
  },
);

export const infoboxMdl = [
  populatedPayerResponseMdl,
  reorderPayersMdl,
  selectPayerFromSearchMdl,
  selectPopulatedPayerMdl,
  updatePolicyActivationMdl,
  updateServiceTypeOnEpicFetchSuccess,
];

import * as R from 'ramda';

import { serviceLevelDisabled } from '../../flags/disabled.flag';
import { PatientType, USER_TYPES } from '../../enums';

const isLocked = ({ service, requests }, type) => !!(service.providers && requests.request.id && type);

const serviceTypeInProvider = (provider, serviceId) =>
  provider && serviceId && provider.serviceTypeIds.includes(serviceId);

const mobilityTypeInProvider = (provider, mobilityId) =>
  provider && mobilityId && provider.mobilityIds.includes(mobilityId);

export const lockedProvider = ({ providers, serviceId }) => providers.find(p => serviceTypeInProvider(p, +serviceId));

const mobilityByProvider = (state, mobilityId) =>
  isLocked(state, +mobilityId) && !mobilityTypeInProvider(lockedProvider(state.service), +mobilityId);

export const serviceTypeByProvider = (state, serviceId) =>
  isLocked(state, +serviceId) && !serviceTypeInProvider(lockedProvider(state.service), +serviceId);

export const showEquipment = ({
  service: { mobilities, mobilityId, patientTypeId, serviceTypes, patientTypes, serviceId },
}) => {
  const { wheelchair } = mobilities;
  const { Curbside, Livery, WAV } = serviceTypes;
  const NICU = patientTypes.find(patientType => patientType.name === R.toLower(PatientType.NICU)) || {};

  if (mobilityId === wheelchair) {
    return false;
  }

  if ([Curbside, Livery, WAV].includes(+serviceId)) {
    return false;
  }

  if (NICU.id === patientTypeId) {
    return false;
  }

  return true;
};

export const bariatricDisabled = state =>
  +state.infobox.patientWeight >= +window.ambulnzConfig.constants.BARIATRIC_WEIGHT_THRESHOLD;

export const walkDisabled = state =>
  mobilityByProvider(state, state.service.mobilities.walk) ||
  !state.service.mobilities.walk ||
  serviceLevelDisabled(state);

export const wheelchairDisabled = state =>
  mobilityByProvider(state, state.service.mobilities.wheelchair) ||
  !state.service.mobilities.wheelchair ||
  serviceLevelDisabled(state);

export const bedboundDisabled = state =>
  mobilityByProvider(state, state.service.mobilities.bedbound) ||
  !state.service.mobilities.bedbound ||
  serviceLevelDisabled(state);

export const serviceTypeDisabled = (serviceTypeId, state) => {
  if (!state.service.serviceId) {
    return true;
  }

  const { request } = state.requests;
  const { serviceTypes } = state.service;
  const { id: scheduleShiftId, serviceTypes: scheduleShiftServiceTypes } = state.scheduleShift;

  if (state.user.model.isRequester && (request.isActive || request.isCompleted)) {
    return true;
  }

  if (scheduleShiftId && (request.isActive || request.isCompleted)) {
    const key = Object.keys(serviceTypes).find(x => serviceTypes[x] === serviceTypeId);
    return !key || !scheduleShiftServiceTypes.includes(key);
  }

  return (
    serviceTypeByProvider(state, serviceTypeId) ||
    !state.service.serviceLevelMap.isEnabled(
      state.service.scope,
      state.service.mobilityId,
      state.service.patientTypeId,
      serviceTypeId,
      state.service.therapistEquipmentIds,
    ).serviceType ||
    serviceLevelDisabled(state)
  );
};

export const NICUPatientDisabled = state => {
  if (R.isEmpty(state.service.patientTypes)) {
    return false;
  }

  const NICU = state.service.patientTypes.find(patientType => patientType.name === R.toLower(PatientType.NICU));
  if (!NICU) {
    return true;
  }

  return !state.service.serviceLevelMap.isEnabled(
    state.service.scope,
    state.service.mobilityId,
    NICU.id,
    state.service.serviceId,
    state.service.therapistEquipmentIds,
  ).patientType;
};

export const NICUDisabled = state => NICUPatientDisabled(state) || serviceLevelDisabled(state);

export const PEDSPatientDisabled = state => {
  if (R.isEmpty(state.service.patientTypes)) {
    return false;
  }

  const PEDS = state.service.patientTypes.find(patientType => patientType.name === R.toLower(PatientType.PEDS));
  if (!PEDS) {
    return true;
  }

  return !state.service.serviceLevelMap.isEnabled(
    state.service.scope,
    state.service.mobilityId,
    PEDS.id,
    state.service.serviceId,
    state.service.therapistEquipmentIds,
  ).patientType;
};

export const PEDSDisabled = state => PEDSPatientDisabled(state) || serviceLevelDisabled(state);

export const AdultPatientDisabled = state => {
  if (R.isEmpty(state.service.patientTypes)) {
    return false;
  }

  const Adult = state.service.patientTypes.find(patientType => patientType.name === R.toLower(PatientType.Adult));
  if (!Adult) {
    return true;
  }
  return !state.service.serviceLevelMap.isEnabled(
    state.service.scope,
    state.service.mobilityId,
    Adult.id,
    state.service.serviceId,
    state.service.therapistEquipmentIds,
  ).patientType;
};

export const AdultDisabled = state => AdultPatientDisabled(state) || serviceLevelDisabled(state);

export const isLightsAndSirensEnabled = state =>
  showEquipment(state) && window.ambulnzConfig.constants.ENABLE_LIGHTS_AND_SIRENS && state.user.model.isDispatcher;

export const enabledTransportReasonsDispatcherEdit = state => {
  if (
    window.ambulnzConfig.constants.ACTIVE_TRANSPORT_REASON_DISPATCHER_EDIT &&
    state.user.model.isDispatcher &&
    state.requests.request.inProgress
  ) {
    return true;
  }

  return false;
};

export const isServiceLevelChangeAllowed = state => {
  const { userType } = state.user.model;
  const isNotRequester = userType && userType !== USER_TYPES.REQUESTER;

  const { request } = state.requests;
  const isActiveOrCompleted = !!request && (request.inProgress || request.isCompleted);

  return isNotRequester && isActiveOrCompleted;
};

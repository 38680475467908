import { concat as concat$, from as from$, of as of$ } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { clearDestinationDepartment } from '../../components/PickupDestination/redux/actions/component.actions';
import LocationService from '../../services/location.service';
import Location from '../../values/location';
import * as locationActions from '../actions/location.actions';
import * as locationComponentActions from '../../components/PickupDestination/redux/actions/component.actions';
import * as mapActions from '../actions/map.actions';
import * as errorActions from '../actions/error.actions';
import * as epicIntegrationActions from '../actions/epicIntegration.actions';
import { autoCompletePipe, errorPipe } from './epicsUtil';
import { getDepartments, getFacilities, getEmsFacilityById } from './helpers/emsFacility.helper';
import { fetchPlaceTypesHelper, getPlaceLocation, searchAddresses, formatAddress } from './helpers/location.helper';
import getActiveAndSelectedDepartments from './helpers/department.helper';

export const searchDestinationInProgressEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryDestinations.match),
    autoCompletePipe,
    map(() => locationActions.setSearchDestinationInProgress(true)),
  );

export const searchDestinationFacilityOnlyInProgressEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryDestinationFacilitiesOnly.match),
    map(() => locationActions.setSearchDestinationInProgress(true)),
  );

export const searchDestinationFacilitiesEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryDestinations.match),
    autoCompletePipe,
    switchMap(action => {
      if (window.ambulnzConfig.constants.GEOPOINT_LOCATIONS_ENABLED) {
        const { ok, value } = LocationService.coordinatesMatcher(action.payload);
        if (ok) {
          return of$(locationActions.queryDestinationLocationsByGeopoint(value)).pipe(errorPipe);
        }
      }

      return getFacilities(action.payload.split(',')[0]).pipe(
        mergeMap(result =>
          concat$(
            of$(locationActions.destinationsFacilitiesRetrieved(result)),
            of$(locationActions.queryDestinationsByAddress(action.payload)),
          ),
        ),
        errorPipe,
      );
    }),
  );

export const searchDestinationAddressesEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryDestinationsByAddress.match),
    switchMap(action => searchAddresses(action.payload)),
    mergeMap(payload =>
      concat$(
        of$(locationActions.destinationsPredictionsRetrieved(payload)),
        of$(locationActions.setSearchDestinationInProgress(false)),
      ),
    ),
    errorPipe,
  );

export const searchDestinationGeopointEpic = action$ =>
  action$.pipe(
    filter(locationActions.queryDestinationLocationsByGeopoint.match),
    switchMap(action =>
      from$(LocationService.reverseGeocode(action.payload)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.destinationGeopointPredictionsRetrieved(payload)),
            of$(locationActions.setSearchDestinationInProgress(false)),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const searchDestinationFacilitiesOnlyEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.queryDestinationFacilitiesOnly.match),
    switchMap(() => getFacilities(store.value.map.destinationPlace.address.street1)),
    mergeMap(result =>
      concat$(
        of$(locationActions.destinationsFacilitiesRetrieved(result)),
        of$(locationActions.destinationsPredictionsRetrieved()),
        of$(locationActions.setSearchDestinationInProgress(false)),
      ),
    ),
    errorPipe,
  );

/* export const autoSelectDestinationOnSingleResultEpic = (action$, store) =>
 *   action$.pipe(
 *  filter(action =>
 *   locationActions.destinationsPredictionsRetrieved.match(action)
 * ),
 *     filter(() => store.value.location.toItems.length === 1),
 *     map(() => locationActions.selectDestinationLocation(store.value.location.toItems[0])),
 *   ); */

export const selectDestinationFromFacilityEpic = action$ =>
  action$.pipe(
    filter(locationActions.selectDestinationLocation.match),
    filter(action => action.payload.typeof === 'facility'),
    mergeMap(action =>
      concat$(
        of$(locationActions.selectDestinationLocationSuccess(action.payload)),
        of$(clearDestinationDepartment()),
        of$(locationActions.getDestinationDepartments(Location.fromFacilitySchema(action.payload).location)),
        of$(
          mapActions.setResidenceDisabled({
            isDestinationResidence: false,
            destinationResidenceDisabled: true,
          }),
        ),
      ),
    ),
  );

export const selectDestinationFromPredictionEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectDestinationLocation.match),
    filter(action => action.payload.typeof === 'prediction'),
    switchMap(action => getPlaceLocation(action.payload, store.value)),
    mergeMap(payload =>
      concat$(
        of$(locationActions.selectDestinationLocationSuccess(payload)),
        of$(
          mapActions.setResidenceDisabled({
            destinationResidenceDisabled: false,
          }),
        ),
      ),
    ),
    errorPipe,
  );

export const selectDestinationFromGeopointPredictionEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectDestinationLocation.match),
    filter(action => action.payload.typeof === 'geopointPrediction'),
    switchMap(action =>
      from$(LocationService.getPlaceLocationFromGeopointPrediction(action.payload, store.value)).pipe(
        mergeMap(payload =>
          concat$(
            of$(locationActions.selectDestinationLocationSuccess(payload)),
            of$(
              mapActions.setResidenceDisabled({
                pickupResidenceDisabled: false,
              }),
            ),
          ),
        ),
        errorPipe,
      ),
    ),
  );

export const setDestinationAddressEpic = (action$, store) =>
  action$.pipe(
    filter(locationActions.selectDestinationLocationSuccess.match),
    switchMap(action => (store.value.location.placeTypeEnum ? of$(action) : fetchPlaceTypesHelper(action))),
    mergeMap(action =>
      concat$(
        of$(locationActions.setDestinationAddress(action.payload)),
        of$(mapActions.setMapToWaypoint(action.payload)),
        of$(mapActions.setMapLocationSuccess(action.payload)),
      ),
    ),
  );

export const clearDestionationLocationEpic = action$ =>
  action$.pipe(
    filter(locationActions.clearDestinationLocation.match),
    mergeMap(() => concat$(of$(mapActions.clearDestinationPlace()), of$(clearDestinationDepartment()))),
  );

export const getDestinationDepartmentsEpic = action$ =>
  action$.pipe(
    filter(locationActions.getDestinationDepartments.match),
    filter(({ payload }) => payload && payload.emsFacilityId),
    switchMap(({ payload }) => getDepartments(payload.emsFacilityId)),
    map(result => locationActions.getDestinationDepartmentsSuccess(result)),
    errorPipe,
  );

export const setExternalRequestDestinationAddressEpic = action$ =>
  action$.pipe(
    filter(epicIntegrationActions.fetchEpicDataSuccess.match),
    filter(({ payload }) => payload.dropOff?.emsFacility?.id), 
    switchMap(({ payload }) => {
      if (payload.error) {
        return of$(errorActions.onError(payload));
      }

      return getEmsFacilityById(payload.dropOff.emsFacility.id).pipe(
        mergeMap(response => {
          const {
            selectedDepartment,
            activeDepartments,
          } = getActiveAndSelectedDepartments(response.emsFacilityDepartments, payload.dropOff.department.id);

          return concat$(
            of$(
              locationActions.selectDestinationLocationSuccess({
                ...response.facilityLocations[0].place,
                name: response.entity.name,
                formattedAddress: formatAddress(response.facilityLocations[0].place),
              }),
            ),
            of$(locationActions.getDestinationDepartmentsSuccess(activeDepartments)),
            of$(locationComponentActions.selectDestinationDepartment(selectedDepartment.id)),
            of$(locationActions.setRoomNumber({ doRoomNumber: payload.dropOff.roomNumber })),
          );
        }),
        errorPipe,
      );
    }),
  );
